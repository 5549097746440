@use 'common'

$gap: 1.375rem
$cardItemTopPadding: 0.3125rem
$cardItemRowHeight: 2.25rem
$borderRadius: 0.625rem

.wrapper
	// @TODO: mobile
	background-color: common.$color-surface-light-tertiary-default
	padding-block: 1.625rem 2.8125rem
	max-width: 240rem // 4k

.main
	display: flex
	flex-wrap: wrap
	gap: 2.5rem

	@media (min-width: common.$break35)
		gap: $gap

	@media (min-width: common.$break48)
		flex-wrap: initial
		justify-content: space-between

.column
	display: flex

	&:not(:first-child)
		gap: $gap

	&:first-child
		flex-direction: column
		gap: 0.625rem

		@media (min-width: common.$break75)
			flex-basis: calc(100% - 48.8125rem)

	// &:last-child
	// 	flex-direction: column

	// 	@media not all and (min-width: common.$break35)
	// 		order: -1

	// 	@media (min-width: common.$break35)
	// 		flex-direction: row

.locales
	margin-top: 1.25rem

.copyright
	font-size: 0.75em
	font-weight: 600
	color: common.$color-surface-light-text-secondary
	margin-top: 4.25rem

	@media (min-width: common.$break48)
		margin-top: auto

.link
	font-size: 0.75em
	font-weight: 600
	color: #000000
	text-decoration: none
	margin-top: 0.6875rem
	font-weight: 700

	&:hover, &:focus-visible
		color: common.$primary-color

	&List
		padding: unset
		margin: unset

		list-style: none
		display: flex
		gap: 0.625rem
		flex-wrap: wrap

.card
	position: relative
	width: 100%
	padding: 1.25rem 1.125rem
	background-color: common.$color-surface-light-secondary-default
	border-radius: $borderRadius

	@media (min-width: common.$break35)
		max-width: 21.75rem

.contact
	&Link
		text-decoration: none
		font-size: 0.875em

		&::after
			content: ''
			position: absolute
			inset: 0
			border: 1px solid common.$color-surface-light-border-tertiary
			border-bottom: 5px solid common.$color-surface-light-border-tertiary
			border-radius: $borderRadius
			opacity: 0
			visibility: hidden
			transition-property: opacity, visibility
			transition-duration: 0.3s
			z-index: 0

		&:hover, &:focus-visible
			&::after
				opacity: 1
				visibility: initial

			.arrow
				transform: translateX(-0.25rem)

	&Content
		height: 100%
		display: flex
		flex-direction: column
		justify-content: space-between
		gap: 3.0625rem

	&Button
		display: flex
		align-items: center
		gap: 0.625rem
		padding-top: $cardItemTopPadding
		height: $cardItemRowHeight

	&Text
		color: common.$color-surface-light-text-tertiary
		font-weight: 600

.info
	height: 100%
	display: flex
	flex-direction: column

	&Row
		padding-block: $cardItemTopPadding
		text-decoration: none

		&In
			height: $cardItemRowHeight
			display: flex
			gap: 1.25rem
			justify-content: space-between
			align-items: center
			border-bottom: 1px solid common.$color-surface-light-border-tertiary

		&:hover, &:focus-visible
			.arrow
				transform: translateX(-0.25rem)

	&Contact
		color: common.$color-surface-light-text-secondary

	&Socials
		display: flex
		gap: 1.8125rem
		flex-wrap: wrap
		margin-top: 1.875rem

	&SocialLink
		// @TODO: hover, focus-visible

	&Icon
		display: flex
		font-size: 1.625rem
		color: common.$color-surface-light-text-tertiary

.buttonWithArrow
	white-space: nowrap
	display: flex
	gap: 0.375rem
	align-items: center
	color: common.$color-surface-light-text-primary

	.arrow
		display: flex
		font-size: 1.25rem
		transition: transform 0.3s
