@use 'common'

.wrapper
	--LogoList-gap: 9vw

	@media (min-width: common.$contentWidth-default)
		--LogoList-gap: 8.5rem

.main

	&.is_scrollAnimated
		overflow: hidden
		-webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent)
		mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent)

		@keyframes scroll
			to
				transform: translate(calc(-50% - var(--LogoList-gap)))

		.list
			width: max-content
			flex-wrap: nowrap
			animation: scroll 40s forwards linear infinite

.list
	display: flex
	align-items: center
	flex-wrap: wrap
	gap: var(--LogoList-gap)

.item
	height: calc(4rem * (0.5 + var(--LogoList-volume)) / 1.5)
	aspect-ratio: var(--LogoList-aspectRatio)
	display: grid
	flex-shrink: 0

	&.is_test
		// box-shadow: 10px 10px 10px 5px rgba(0, 0, 0, 0.5)

.image
	width: 100%
	height: auto
