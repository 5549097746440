@use 'common'

.label
	--Inputs-input-border-color-default: #{common.$color-surface-light-border-primary-default}
	--Inputs-input-border-color-hover: #{common.$color-surface-light-border-primary-hover}
	--Inputs-input-border-color-active: #{common.$color-surface-light-border-primary-active}
	--Inputs-input-border-color-state: var(--Inputs-input-border-color-default)

	outline: unset
	font-size: 0.875em
	font-weight: 600
	color: common.$color-surface-light-text-secondary

	&Input, &TextArea
		position: relative
		z-index: 0
		display: flex

	&Text
		margin-bottom: 0.125rem

	&:hover
		--Inputs-input-border-color-state: var(--Inputs-input-border-color-hover)

	.is_activeBorder
		position: absolute
		inset: 0
		border: 2px solid var(--Inputs-input-border-color-active)
		border-radius: 0.625rem
		z-index: -1
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: 0.3s

.required
	color: common.$color-text-tertiary

.input
	border: unset
	background-color: transparent
	outline: unset

	width: 100%
	padding: 1rem
	font-family: var(--font-body)
	transition-property: color, background-color, border-color
	transition-duration: 0.3s
	color: common.$color-surface-light-text-secondary

	&:focus-visible
		--Inputs-input-border-color-state: var(--Inputs-input-border-color-active)
		color: common.$color-surface-light-text-primary

	&.is_small
		border: 1px solid var(--Inputs-input-border-color-state)
		border-radius: 0.625rem
		padding: 0.5625rem 0.9375rem
		font-size: 1em
		position: relative
		z-index: 0

		&:focus-visible ~ .is_activeBorder
			visibility: visible
			opacity: 1

	&.is_large
		padding: 0.4375rem 0.625rem 0.6875rem
		border-bottom: 1px solid var(--Inputs-input-border-color-state)
		font-size: 1.25em

		&:focus-visible
			background-color: common.$color-surface-light-tertiary-default

	&.is_textarea
		resize: vertical
		min-height: 7.8125em
