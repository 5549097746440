@use 'common'

.wrapper
	display: flex
	gap: 0.875rem
	cursor: pointer
	width: fit-content

.checkbox
	$checkbox: &

	margin: unset
	position: absolute
	height: 0
	width: 0
	opacity: 0

	&:hover, &:focus-visible
		~ #{$checkbox}Custom
			border-color: common.$color-grayscale-950

		~ .label
			color: common.$color-surface-light-text-primary

	&:checked
		~ #{$checkbox}Custom
			background-color: common.$color-grayscale-950
			border-color: common.$color-grayscale-950

			&::before
				content: ''
				visibility: initial
				opacity: 1

		~ .label
			color: common.$color-surface-light-text-primary

	&Custom
		position: relative
		align-self: center
		flex-shrink: 0
		width: 1em
		height: 1em
		border: 1px solid common.$color-grayscale-900
		border-radius: .25rem
		z-index: 0
		transition-property: background-color, border-color
		transition-duration: .2s

		&::before
			content: ''
			position: absolute
			top: 50%
			left: 50%
			width: 0.625em
			height: 0.375em
			transform: translate(-50%, -70%) rotate(-45deg)
			visibility: hidden
			opacity: 0
			border-left: .125em solid common.$color-grayscale-0
			border-bottom: .125em solid common.$color-grayscale-0
			transition-property: visibility, opacity
			transition-duration: .2s

.label
	font-size: 0.875em
	font-family: var(--font-body)
	color: common.$color-surface-light-text-primary
	font-weight: 600
	position: relative
	margin-block: unset

.required
	font-size: inherit
	color: common.$color-text-tertiary
