@use 'common'

$featuresMaxWidth: 63.8125rem

.wrapper
	--Features-featuresMaxWidth: 1021
	--Features-row-listGap: 3.75rem
	--Features-column-listGap: 1.25rem

	@media (min-width: common.$break30)
		--Features-row-listGap: var(--Features-column-listGap)

	@media (min-width: common.$break40)
		--Features-row-listGap: 2.5rem
		--Features-column-listGap: var(--Features-row-listGap)

.title
	+common.h1

	position: relative
	text-align: center
	margin-block: 0
	z-index: 0

.features
	margin-top: 2.5rem
	max-width: $featuresMaxWidth
	margin-inline: auto

	@media (min-width: common.$break48)
		margin-top: 5.625rem

.list
	$firstListFirstItemWidth: 375
	$firstListFirstItemHeight: 440
	$firstListSecondItemWidth: 605
	$firstListSecondItemHeight: 435
	$secondListFirstItemWidth: 520
	$secondListFirstItemHeight: 435
	$secondListSecondItemWidth: 375
	$secondListSecondItemHeight: 655
	--Features-firstItem-topOffset: 5.5vw

	display: grid
	column-gap: var(--Features-column-listGap)

	&:not(:first-child)
		margin-top: var(--Features-row-listGap)

	&:first-child
		@media (min-width: common.$break48)
			grid-template-columns: calc(#{$firstListFirstItemWidth} / var(--Features-featuresMaxWidth) * 100%) calc(#{$firstListSecondItemWidth} / var(--Features-featuresMaxWidth) * 100%)

			.item
				&:nth-child(1)
					top: var(--Features-firstItem-topOffset)
					aspect-ratio: $firstListFirstItemWidth / $firstListFirstItemHeight

				&:nth-child(2)
					aspect-ratio: $firstListSecondItemWidth / $firstListSecondItemHeight

	&:nth-child(2)
		@media (min-width: common.$break48)
			grid-template-columns: calc($secondListFirstItemWidth / var(--Features-featuresMaxWidth) * 100%) calc($secondListSecondItemWidth / var(--Features-featuresMaxWidth) * 100%)
			justify-content: center

			.item
				&:nth-child(1)
					top: var(--Features-firstItem-topOffset)
					aspect-ratio: $secondListFirstItemWidth / $secondListFirstItemHeight

				&:nth-child(2)
					aspect-ratio: $secondListSecondItemWidth / $secondListSecondItemHeight

	@media (min-width: common.$break30)
		grid-template-columns: 1fr 1fr

	@media (min-width: $featuresMaxWidth)
		--Features-firstItem-topOffset: 4.25rem

.item
	display: flex
	position: relative
	z-index: 0
	aspect-ratio: 82 / 75 // 328 / 300

	@media not all and (min-width: common.$break30)
		&:nth-child(2)
			margin-top: var(--Features-row-listGap)

.image
	z-index: -1
	object-fit: cover
	border-radius: 1.25rem
	overflow: hidden

.accordion
	//
