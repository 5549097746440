@use 'common'

$-tableBorderColor: #EBEBEB

.wrapper
	--ContentRenderer-gap: var(--common-content-gap)

.section

	&:not(.is_wysiwyg) + &,
	& + &:not(.is_wysiwyg)
		padding-top: var(--ContentRenderer-gap)

	&:last-child
		padding-bottom: var(--ContentRenderer-gap)

	[data-contember-type="table"]
		border: 0.125rem solid $-tableBorderColor
		border-spacing: 0
		border-collapse: collapse

	[data-contember-type="tableRow"]
		//

	[data-contember-type="tableCell"]
		padding: 0.9375em
		border: 0.125rem solid $-tableBorderColor

	[data-contember-type="scrollTarget"]
		scroll-margin-top: 9.375rem

.notImplemented
	border: 0.0625rem solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
