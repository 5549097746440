@use 'common'

$contentsShadowWidth: 10vw
$contentsMaxWidth: 50rem

.wrapper
	//

.title
	+common.h1

	position: relative
	text-align: center
	margin-block: 0 2.8125rem
	z-index: 0

.shape
	display: flex
	max-width: calc(var(--TabRevealingTextList-shapeIn-width) * 1px)
	width: 50vw
	position: absolute
	left: 50%
	bottom: -0.625rem
	transform: translateX(-50%)
	z-index: -1

	&In
		width: 100%
		height: auto

.tabs
	display: flex
	justify-content: center
	flex-wrap: wrap

.tab
	border: unset
	outline: unset
	background: transparent

	padding: 0.625rem 1.25rem
	color: common.$color-surface-light-text-secondary
	font-size: 1.25rem
	border-radius: 0.625rem
	font-weight: 700
	font-family: var(--font-body)
	transition-property: background-color, color
	transition-duration: 0.4s
	transition-timing-function: ease-in-out

	&.is_active
		background-color: common.$color-surface-light-border-tertiary
		color: common.$color-surface-light-text-primary

.contents
	display: flex
	align-items: flex-start
	margin-top: 4.0625rem
	max-width: calc($contentsMaxWidth + $contentsShadowWidth)
	margin-inline: auto
	position: relative
	overflow: hidden

	&::before
		content: ''
		position: absolute
		inset: 0
		mask-image: linear-gradient(90deg, black 0%, #ffffff $contentsShadowWidth, #ffffff $contentsMaxWidth, black 100%)
		// for debugging purposes with mask-image
		// background-image: linear-gradient(90deg, black 0, #ffffff $contentsShadowWidth, #ffffff $contentsMaxWidth, black 100%)

.content
	padding-inline: calc((100% - $contentsMaxWidth) / 2)
	flex-shrink: 0
	width: 100%
	margin-inline: auto
	color: common.$color-surface-light-text-tertiary
	font-size: 1.625rem
	line-height: 1.5
	text-align: center
	position: relative
	z-index: 0
	transition-property: opacity, visibility, transform
	transition-duration: 1s
	transition-timing-function: cubic-bezier(0.35, 0, 0, 1)
	transition-delay: 0.1s

	@media (min-width: common.$break62)
		font-size: 2.125rem

	&:not(:first-child)
		margin-left: -100%

	&.is_active
		height: auto
		visibility: inherit

	&:not(.is_active)
		height: 0
		transform: translateX(-100%)
		opacity: 0
		visibility: hidden

	&.is_active ~ &
		transform: translateX(100%)
