@use 'common'

.wrapper
	//

.main
	display: flex
	flex-direction: column
	gap: 3.125rem

	@media (min-width: common.$break48)
		gap: 5vw
		flex-direction: row

	@media (min-width: common.$break62)
		gap: 8.125rem

.content
	display: flex
	flex-direction: column
	gap: 1.5625rem

	@media (min-width: common.$break48)
		margin-top: 1.25rem
		flex-basis: 35.625rem

.title, .text
	margin: unset

.media
	width: 100%
	max-width: 35rem
	display: flex
	justify-content: center
	align-items: center
	align-self: center
	position: relative
	z-index: 0

.image
	position: relative
	display: flex
	justify-content: center
	max-width: calc(var(--AsideMediaContent-image-width) * 1px)
	aspect-ratio: calc(var(--AsideMediaContent-image-width) / var(--AsideMediaContent-image-height))
	width: 100%
	z-index: 0
	border-radius: 1.25rem
	overflow: hidden

	&In
		width: 100%

.videos
	display: flex
	justify-content: center
	align-items: center
	aspect-ratio: 560 / 540
	position: relative
	z-index: 0
	width: 100%

.video

	&List
		aspect-ratio: 315 / 540
		max-width: 19.6875rem
		position: relative
		z-index: 0
		height: 100%

	&Item
		--AsideMediaContent-item-dispersion: 40%
		--AsideMediaContent-item-degree: calc((360deg / var(--AsideMediaContent-item-count)) * var(--AsideMediaContent-item-index))
		--AsideMediaContent-item-traslateX: translateX(calc(var(--AsideMediaContent-item-dispersion) * sin(var(--AsideMediaContent-item-degree))))

		display: flex
		align-items: center
		max-height: 33.75rem
		height: 100%
		position: absolute
		top: 0
		left: 0
		transition: transform 0.5s ease
		z-index: -1
		opacity: 0.3
		transform: var(--AsideMediaContent-item-traslateX) scale(0.8)

		&:not(.is_active)
			cursor: pointer

		&.is_active
			opacity: 1
			z-index: 1
			transform: var(--AsideMediaContent-item-traslateX) scale(1)

	&In
		width: 100%
		border-radius: 1.25rem
		overflow: hidden
