@use 'common'

$checkboxVerticalSpacing: 0.9375rem

.wrapper
	//

.title
	margin-block: 0 2.5rem

.row
	margin-top: 2.5rem
	container-type: inline-size

.columns
	display: flex
	flex-direction: column
	row-gap: 1.25rem
	column-gap: 1.875rem

	@container (min-width: 25rem)
		flex-direction: row

.column
	flex-basis: 100%

.description
	margin-top: 0.3125rem
	font-size: 0.75em
	color: common.$color-surface-light-text-tertiary

	&.is_checkbox
		margin-top: $checkboxVerticalSpacing

.checkbox
	margin-top: $checkboxVerticalSpacing

	a
		&:hover
			text-decoration: none

.submit
	text-align: end
	margin-top: 1.875rem
