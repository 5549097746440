@use 'common'

$verticalSpacing: 0.5625rem

.wrapper
	text-decoration: none
	margin-block: -$verticalSpacing

	&:hover, &:focus-visible
		.arrow
			transform: translateX(-0.25rem)

.main
	display: flex
	justify-content: space-between
	gap: 0.9375rem
	padding-block: $verticalSpacing
	font-size: 0.875rem
	color: common.$color-text-secondary

.label
	font-size: 0.875rem

.button
	display: flex
	align-items: center
	gap: 0.5rem

.buttonLabel
	font-weight: 700

.arrow
	display: flex
	font-size: 1.25rem

	@media (prefers-reduced-motion: no-preference)
		transition: transform 0.3s
