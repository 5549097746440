@use 'common'

.wrapper
	display: inline-block
	width: 2.375rem
	height: 2.375rem
	margin-left: 0.25em
	position: relative
	z-index: 0
	border-radius: 0.9375rem
	overflow: hidden
	vertical-align: middle

.image
	object-fit: cover
	position: relative
	z-index: 0
	width: 2.5rem
	height: 2.5rem
