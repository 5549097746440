@use 'common'
@use './HeroAnimation.module' as HeroAnimation

.wrapper
	display: flex
	flex-direction: column
	min-height: 100vh
	position: relative

	&.is_homepage
		.header
			&Wrapper
				@media (min-aspect-ratio: 7/9)
					position: absolute
					inset: 0 0 auto
					z-index: 1

// @TODO: gaptop
// .gap
// 	&Top
// 		padding-top: var(--common-header-top-gap)

.header
	position: relative
	z-index: 0

	&Wrapper
		//

	&::after
		content: ''
		position: absolute
		inset: 0
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: 0.4s
		z-index: -1

	&.is_background
		&::after
			visibility: initial
			opacity: 1

.main
	flex-grow: 1

.footer
	//

.waitlistButton
	position: absolute
	// padding-top: calc(2 * var(--header-height) - 1.75rem + var(--Layout-waitlistButton-offset))
	padding-top: var(--Layout-waitlistButton-paddingTop)
	height: 100%
	inset: 0
	pointer-events: none
	z-index: 11

	&In
		display: flex
		justify-content: center
		position: sticky
		top: var(--header-height)
		transform: translateY(calc(-50% + var(--header-height) / -2))
		pointer-events: all

	&Icon
		display: flex
		font-size: 1.375rem
