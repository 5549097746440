@use 'common'

$activeColor: #e8e2d8
$hoverOrFocusColor: rgba(240, 238, 234, 0.40)
$transitionHeaderDuration: 0.4s
$borderRadius: 1.5625rem
$offsetLength: 0.0625rem

.wrapper
	$wrapper: &
	--Features-accordion-spacing: 1.25rem

	display: flex
	flex-direction: column
	width: 100%
	padding-block: 1.5rem
	padding-inline: var(--Features-accordion-spacing)

	@media not all and (min-width: common.$break48)
		padding: 0.9375rem
		margin-inline: auto

	@media (min-width: common.$break48)
		max-width: calc(19.375rem + 2 * var(--Features-accordion-spacing))

	&.is_textColor_light
		.header, .label, .icon
			color: common.$color-kurmu-50

	&.is_textColor_dark
		.header, .label, .icon
			color: common.$color-surface-light-text-primary

	&.is_vertical_top
		.header
			&::after
				border-top-left-radius: $borderRadius
				border-top-right-radius: $borderRadius

		.body
			border-bottom-left-radius: $borderRadius
			border-bottom-right-radius: $borderRadius
			margin-top: -1px

	&.is_vertical_bottom
		flex-direction: column-reverse

		.header
			&::after
				border-bottom-left-radius: $borderRadius
				border-bottom-right-radius: $borderRadius

		.body
			border-top-left-radius: $borderRadius
			border-top-right-radius: $borderRadius
			margin-bottom: -1px

	&.is_horizontal_left
		//

	&.is_horizontal_center
		@media (min-width: common.$break48)
			margin-inline: auto

	&.is_horizontal_right
		@media (min-width: common.$break48)
			margin-left: auto

	&.is_open
		.header
			background-color: $activeColor

			&::before
				visibility: hidden
				opacity: 0

			&::after
				visibility: initial
				opacity: 1

		.label, .icon
			color: common.$color-surface-light-text-primary

		.icon
			&Plus
				visibility: hidden
				opacity: 0

			&Minus
				visibility: initial
				opacity: 1

		.body
			background-color: $activeColor

.header
	$borderRadius: 1.5625rem
	background: transparent
	border: 0
	outline: 0
	margin: 0
	width: 100%
	text-align: left

	display: flex
	justify-content: space-between
	position: relative
	padding: 0.875rem var(--Features-accordion-spacing)
	font-size: 1.25rem
	border-radius: 1.5625rem
	z-index: 0
	transition: background-color $transitionHeaderDuration
	transform-style: preserve-3d

	.gradient
		position: absolute
		inset: -$offsetLength
		border: $offsetLength solid transparent
		z-index: -1
		border-radius: $borderRadius + $offsetLength

		-webkit-mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0)
		-webkit-mask-composite: xor
		mask-composite: exclude

		&In
			position: absolute
			inset: -1px
			background: linear-gradient(90deg, rgba(#616161, 0.2) 20%, rgba(#fdfdfc, 0.5) 50%, rgba(#616161, 0.2) 80%)
			backdrop-filter: blur(1.875rem)
			border-radius: $borderRadius
			z-index: -2

	@media (min-width: common.$break48)
		&:hover, &:focus-visible

			.label, .icon
				color: common.$color-surface-light-border-primary-active

			&::before
				background-color: $hoverOrFocusColor
				border: 0

	&::before
		content: ''
		position: absolute
		inset: 0
		background-color: rgba(240, 238, 234, 0.10)
		box-shadow: 0.25rem 0.375rem 0.5rem 0 rgba(0, 0, 0, 0.20)
		backdrop-filter: blur(1.875rem)
		z-index: -1
		border-radius: $borderRadius
		transition-property: visibility, opacity, background-color, color
		transition-duration: $transitionHeaderDuration

	&::after
		content: ''
		background-color: $activeColor
		position: absolute
		top: 0
		left: 0
		right: 0
		bottom: 0
		z-index: -1
		transition-property: visibility, opacity
		transition-duration: $transitionHeaderDuration
		visibility: hidden
		opacity: 0

.icon
	position: relative
	z-index: 0
	display: flex
	font-size: 1.5rem
	margin-top: 0.125rem

	&Minus
		position: absolute
		inset: 0
		visibility: hidden
		opacity: 0

	&Plus, &Minus
		display: flex
		transition-property: visibility, opacity
		transition-duration: 0.3s

.label, .icon
	transition: color $transitionHeaderDuration

.label
	font-weight: 600
	font-family: var(--font-body)

	@media (min-width: common.$break30) and (max-width: common.$break40)
		font-size: clamp(1.125rem, 1.5vw, 1.25rem)

.body
	display: flex
	// @TODO: fix border-radius, they are different from the header (at least the values, on the website looks "similar")
	transition: background-color $transitionHeaderDuration
	z-index: 1

	@media not all and (min-width: common.$break48)
		flex-grow: 1

	&Text
		padding: var(--Features-accordion-spacing)
		font-weight: 600
