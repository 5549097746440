@use 'common'
$contentVerticalGap: 1.5625rem

.wrapper
	container-type: inline-size

.main
	display: grid
	gap: 7.5rem

	@container (width > #{common.$break48})
		--ContactWaitlist-main-content-minWidth: 45%
		justify-content: space-between
		grid-template-columns: minmax(var(--ContactWaitlist-main-content-minWidth), 35.375rem) minmax(40%, 31.375rem)
		gap: 3.125rem

	@container (width > #{common.$break75})
		--ContactWaitlist-main-content-minWidth: 38.125rem

.content
	display: flex
	flex-direction: column
	justify-content: space-between
	gap: 5.625rem

	@media (min-width: common.$break64)
		gap: 2.5rem

.contentRow
	display: flex
	flex-direction: column
	gap: $contentVerticalGap

	@container (width < #{common.$break48})
		align-items: center

.background
	position: relative
	z-index: -1
	width: 100%

.shape
	container-type: inline-size
	display: flex
	justify-content: center
	position: absolute
	top: -1.5rem
	z-index: -1
	max-width: 31.0625rem
	width: 100%

	&In
		width: 100%
		height: auto

		&.is_mobile
			max-width: 24.125rem

			@media (min-width: common.$break64)
				display: none

		&.is_desktop
			max-width: 31.0625rem

			@media not all and (min-width: common.$break64)
				display: none

	@media not all and (min-width: common.$break64)
		transform: translateX(-50%) // to make ot optically centered
		left: 50%

	@media (min-width: common.$break64)
		right: -5vw
		top: 0.75rem

.photo
	//

.title
	margin: 0
	max-width: 16.875rem

	@media (min-width: common.$break40)
		max-width: 26.875rem

	@media (min-width: common.$break48)
		max-width: unset

.text
	font-size: 1.375rem
	max-width: 25rem
	margin: 0
	color: common.$color-surface-light-text-tertiary

.title, .text
	@container (width < #{common.$break48})
		text-align: center

.contactPersonsWrapper
	height: 100%
	display: flex
	flex-direction: column

.contactPersons
	container-type: inline-size
	list-style-type: none
	padding: 0
	margin: 0
	display: grid
	gap: 1.25rem
	position: sticky
	bottom: 1.25rem
	margin-top: auto

.contactPerson
	display: flex
	justify-content: space-between
	gap: 1.25rem

	@container (width < 31.875rem)
		flex-direction: column

.contactImage
	width: 4.6875rem
	height: 4.6875rem
	position: relative
	border-radius: 1.5625rem
	overflow: hidden
	z-index: 0

.contactContent
	display: flex
	flex-direction: column
	gap: 0.25rem

.contactName
	font-size: 1.25rem
	font-weight: 700

.contactPosition
	font-size: 0.875rem
	font-weight: 600
	color: common.$color-text-tertiary

.contactColumn
	display: flex
	gap: 1.25rem

.contactInfo
	display: flex
	flex-direction: column

.contactInfoRow
	border-bottom: 0.0625rem solid #E8E2D8 // @TODO: add to variable

.form
	padding: 1.875em
	box-shadow: 0.625rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05)
	background-color: common.$color-surface-light-primary-default
	border-radius: 1.25rem
