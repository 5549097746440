@use 'common'

$borderRadius: 0.625rem

=tertiaryHoverAndFocus

.wrapper
	position: relative
	display: inline-flex
	align-items: center
	isolation: isolate
	overflow: hidden
	text-align: center
	justify-content: center
	font-family: inherit
	font-weight: 500
	cursor: pointer
	border: unset
	text-decoration: none

	&:disabled
		opacity: 0.5
		cursor: default

	&.is_fullWidth
		width: 100%

	&.is_size_small
		font-size: 0.875rem
		line-height: normal

	&.is_size_large
		font-size: 1rem
		line-height: normal

	// @TODO: add hover and focus-visible styles
	&.is_variant_primary
		// @TODO: gradient
		background-color: common.$primary-color
		color: common.$color-surface-light-text-primary
		padding: 0.875rem 1.875rem
		border-radius: $borderRadius
		// @TODO: box-shadow will (may)be adjusted together with hover/focus-visible styles
		box-shadow: (0px -2px 2px 0px rgba(0, 0, 0, 0.20) inset, 1px 0px 2px 0px rgba(0, 0, 0, 0.15) inset, -1px 0px 2px 0px rgba(0, 0, 0, 0.15) inset, 0px 2px 2px 0px rgba(251, 251, 251, 0.40) inset)

	&.is_variant_secondary
		color: common.$color-surface-light-text-primary
		background-color: common.$color-surface-light-tertiary-default
		padding: 0.875rem
		border-radius: $borderRadius

		.main
			display: flex
			align-items: center
			justify-content: center
			gap: 1.25rem

		.icon
			width: 1.875rem
			aspect-ratio: 1
			position: relative
			z-index: 0

			&In
				//

	&.is_variant_form
		background-color: common.$color-grayscale-900
		color: common.$color-kurmu-50
		padding: 0.375rem 1.25rem
		border-radius: $borderRadius
		// @TODO: box-shadow will (may)be adjusted together with hover/focus-visible styles
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset, 0px 1px 2px var(--Corners_0, 0px) rgba(251, 251, 251, 0.70) inset, 0px -4px 2px 0px rgba(0, 0, 0, 0.40) inset

	&.is_variant_tertiary
		$spacing: 0.3125rem
		color: common.$color-surface-light-text-primary
		padding: 0

		&.is_blur
			border-radius: $borderRadius
			$blurSpacing: 0.625rem

			margin: -$blurSpacing
			.main
				padding: $blurSpacing

		&:not(.is_blur)
			margin: -$spacing

			.main
				padding: $spacing

		.main
			display: flex
			align-items: center
			justify-content: center
			gap: 0.375rem

		.arrow
			display: flex
			font-size: 1.25rem
			transition: margin-left 0.3s

		.icon
			font-size: 1.3125rem
			transition: margin-right 0.3s

		&::before
			position: absolute
			inset: 0.0625rem 0.0625rem 0.3125rem
			border-radius: $borderRadius - 0.0625rem
			background-color: common.$color-surface-light-secondary-default
			z-index: -1

		&::after
			inset: 0
			background-color: common.$color-surface-light-border-tertiary
			position: absolute
			z-index: -2
			border-radius: $borderRadius

		&::before, &::after
			content: ''
			opacity: 0
			visibility: hidden
			transition-property: opacity, visibility
			transition-duration: 0.3s

		&:hover, &:focus-visible
			&:not(:disabled)
				.icon
					margin-right: -0.25rem

				.arrow
					margin-left: -0.25rem

				&::before, &::after
					visibility: initial
					opacity: 1

	&.is_variant_text
		color: inherit
		background-color: transparent
		color: var(--brand-color)
		padding: 0

		&:hover,
		&:focus-visible
			&:not(:disabled)
				color: var(--brand-color)

.blur
	position: absolute
	inset: 0
	transition-property: opacity, visibility
	transition-duration: 0.3s
	backdrop-filter: blur(1.875rem)
	z-index: -2

.icon
	display: flex
