@use 'common'

.wrapper
	//

.hero
	//

.main
	min-height: 100vh
	min-height: 100dvh
	display: grid
	grid-template-columns: 100%
	grid-template-rows: 1fr auto 1fr
	flex-direction: column
	align-items: center
	justify-items: center

	&::before
		content: ''

	.background
		max-width: min(75rem, 80vw, 130vh)
		width: 100%
		position: relative

		@media not all and (min-width: common.$break30)
			max-width: min(75rem, 95vw, 130vh)

	.logo
		z-index: 1
		position: absolute
		top: 50%
		left: 50%
		transform: translate(-50%, -50%)
		aspect-ratio: 100 / 59
		max-width: 18.75rem
		width: 30vw

		@media not all and (min-width: common.$break30)
			width: 40vw

	.button
		display: flex
		justify-content: center

		@media (min-width: common.$break40)
			align-self: start
			margin-top: max(-4vw, -6.25rem, -10vh)
