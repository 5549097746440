@use 'common'

.wrapper
	//

.header
	display: flex
	justify-content: space-between
	align-items: center

.controls
	display: flex

	@media not all and (min-width: common.$break48)
		display: none

.button
	$button: &
	border: unset
	background: transparent
	outline: none

	position: relative
	padding: 0.625rem
	font-size: 1.25rem
	z-index: 0

	&In
		display: flex

	&::after
		content: ''
		position: absolute
		border-radius: 0.625rem
		inset: 0
		border: 1px solid common.$color-surface-light-border-tertiary
		border-bottom: 5px solid common.$color-surface-light-border-tertiary
		opacity: 0
		transition: opacity 0.3s
		z-index: -1

	&.is_next
		//

	&.is_previous
		#{$button}
			&In
				transform: scale(-1)

	&:hover, &:focus-visible
		&::after
			opacity: 1

.title
	margin: 0

	@media not all and (min-width: common.$break30)
		font-size: 1.5rem

	@media not all and (min-width: common.$break48)
		text-align: center

.empty
	display: block
	scroll-snap-align: start

.items
	$blockSpacing: 1.25rem
	+common.hideHorizontalScrollbar

	width: 100%
	display: flex
	gap: 1.25rem
	flex-wrap: nowrap
	margin-top: 1.625rem - $blockSpacing
	scroll-snap-type: x mandatory
	padding-block: $blockSpacing

	@media (min-width: common.$break48)
		gap: 2.5rem

.item
	min-width: 80%
	height: fit-content
	padding: 1.25rem
	background-color: common.$color-surface-light-secondary-default
	box-shadow: 0.625rem 0.25rem 1.25rem 0 rgba(0, 0, 0, 0.05)
	border-radius: 1.25rem
	scroll-snap-align: start

	@media (min-width: common.$break48)
		min-width: 30rem

.image
	aspect-ratio: 440 / 240
	position: relative
	border-radius: 0.625rem
	overflow: hidden
	z-index: 0

	&In
		object-fit: cover

.logo
	display: flex
	margin-top: 1.3125rem

.content
	display: flex
	flex-direction: column
	gap: 1.0625rem
	margin-top: 0.875rem

.text
	margin: 0
	font-size: 1.25rem
	font-weight: 600
	line-height: normal
	color: common.$color-surface-light-border-primary-hover

.footer
	display: flex
	align-items: center
	gap: 1.0625rem
	font-size: 0.875rem

.label
	font-weight: 700
	padding: 0.25rem 0.3125rem
	background-color: common.$color-surface-light-tertiary-default
	color: common.$color-surface-light-border-primary-hover
	border-radius: 0.3125rem

.name
	color: common.$color-surface-light-text-tertiary
