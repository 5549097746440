@use 'common'

.wrapper
	//

.in
	display: flex
	justify-content: space-between
	align-items: center
	column-gap: 1rem
	padding-block: 1.5rem

	@media (min-width: common.$break48)
		padding-block: 1.75rem

.logo
	width: calc((var(--Header-logo-width) * 0.7) * 0.0625rem)
	display: flex
	position: relative
	gap: 0.25rem
	aspect-ratio: var(--Header-logo-width) / var(--Header-logo-height)

	@media (min-width: common.$break48)
		width: calc(var(--Header-logo-width) * 0.0625rem)
