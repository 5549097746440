@use 'common'

.wrapper
	display: flex
	max-width: 100%

.shape
	display: flex
	width: 100%
	height: auto
