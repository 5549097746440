@use 'common'

.wrapper
	//

.title
	margin-block: 0 2.5625rem
	text-align: center

.medium
	display: flex
	justify-content: center

	&.image
		//

	&.video
		max-width: calc(var(--Medium-video-width) * 1px)

	.image, .video
		border-radius: 1.25rem

.image
	width: 100%
	height: auto

.video
	width: 100%
