@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions
	&-instagram
		+normalizeSize(41, 40)
	&-linkedin
		+normalizeSize(26, 26)
	&-x
		+normalizeSize(26, 26)
	&-plusCirle,
	&-minusCirle
		+normalizeSize(24, 24)
	&-arrow
		+normalizeSize(21, 20)
	&-kilomayo
		+normalizeSize(22, 22)
	&-globe
		+normalizeSize(21, 21)
