@use 'common'

$borderWidth: 0.1875rem
$backgroundOffset: $borderWidth
$transitionDuration: 1s
$transitionDelay: 0.1s
$transitionTimingFunction: cubic-bezier(0.35, 0, 0, 1)
$verticalShift: -10svh
$imageHorizontalTranslate: -50%
$windowHeightMobile: 75svh
$windowHeightDesktop: 60svh
$windowMaxWidth: 22.5rem
$fullHeightWithoutHeaderHeight: calc(100svh - var(--header-height))

=heroAnimationStyles($isDesktop: false)
	position: relative
	display: flex
	justify-content: center

	@if $isDesktop
		margin-top: -3svh
		z-index: 1

	@else
		margin-top: -10svh
		z-index: -1

=imageStyles
	position: absolute
	height: $fullHeightWithoutHeaderHeight
	left: 50%
	bottom: 0
	transform: translateX($imageHorizontalTranslate)
	object-fit: none
	transition-duration: $transitionDuration
	transition-timing-function: $transitionTimingFunction
	transition-delay: $transitionDelay

.wrapper
	.is_mobile
		@media (min-aspect-ratio: 7/9)
			display: none

	.is_desktop
		@media not (min-aspect-ratio: 7/9)
			display: none

.hero
	&Button
		position: absolute
		inset: 0
		padding-top: calc(var(--header-height) + (var(--waitlistButton-height) / 2))
		pointer-events: none

		&In
			position: sticky
			top: 1.25rem
			display: flex
			justify-content: center
			align-items: center
			width: 100%
			height: var(--waitlistButton-height)
			z-index: 100
			pointer-events: all

		@media (min-aspect-ratio: 7/9)
			// the --waitlistButton-offsetTop value here is calculated via javascript in the HeroAnimationDesktop component
			padding-top: calc(var(--waitlistButton-offsetTop))
