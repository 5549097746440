@use 'common'

.wrapper
	//

.link
	display: flex
	align-items: center
	gap: 0.375rem
	text-decoration: none
	color: common.$color-surface-light-text-primary
	font-size: 0.875rem
	font-family: var(--font-body)
	font-weight: 700

	&.is_padding
		padding: 0.625rem

.logo
	display: flex
	font-size: 1.25rem
	color: common.$color-surface-light-text-tertiary
